export default function (tableApi, isComponent = false, silent = false) {
  let mixin = {
    data() {
      return {
        tableApi: tableApi,
        tableLoading: false,
        tableConfig: {
          layout: 'total, sizes, prev, pager, next, jumper',
        },
        tableQuery: {
          page: 1,
          size: 20,
        },
        tableData: {
          data: [],
          total: 0,
        },
      };
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.search();
      });
    },
    created() {
      this.search();
    },
    methods: {
      getTable() {
        this.tableLoading = true;
        return this.tableApi(this.tableQuery)
          .then((res) => {
            this.tableLoading = false;
            if (res.data.code == 0) {
              res.data.data = res.data.data || [];
              if (this.mixinParseData) {
                res.data.data = this.mixinParseData(res.data.data);
              }
              this.$set(this.tableData, 'data', res.data.data);
              this.tableData.total = res.data.total || res.data.data.length;
              this.tableData.total = parseInt(this.tableData.total);
            } else {
              throw new Error();
            }
          })
          .catch((err) => {
            this.tableLoading = false;
            console.warn(err);
            if (this.mixinError) {
              this.mixinError(err);
            }
            if (!silent) {
              this.$message.error('获取列表时接口错误');
            }
          });
      },
      search() {
        if (this.$refs.queryForm) {
          this.$refs.queryForm.validate((isValidate) => {
            if (isValidate) {
              this.tableQuery.page = 1;
              this.getTable();
            }
          });
        } else {
          this.tableQuery.page = 1;
          this.getTable();
        }
      },
      // 每页数量切换
      handleSizeChange(val) {
        this.tableQuery.page = 1;
        this.tableQuery.size = val;
        this.tableData.data = [];
        this.getTable();
      },
      // 页数切换
      handleCurrentChange(val) {
        this.tableQuery.page = val;
        this.tableData.data = [];
        this.getTable();
      },
    },
  };
  if (isComponent) {
    delete mixin.beforeRouteEnter;
  } else {
    delete mixin.created;
  }
  return mixin;
}
