<template>
  <div class="admin-view-container">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item label="流水号" style="width: 18%">
          <el-input v-model="tableQuery.orderFlowNo" clearable></el-input>
        </el-form-item>
        <el-form-item label="变更原因" style="width: 18%">
          <el-select v-model="tableQuery.orderFlowType" placeholder="全部" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="1" label="系统充值"></el-option>
            <el-option value="10" label="服务结算"></el-option>
            <el-option value="20" label="退款结算"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属驾校" style="width: 18%">
          <select-dict-remote
            filterable
            show-all
            v-model="tableQuery.drvSchoolCode"
            :api="drvSchoolGetSchool"
            :query="{getType: 2}"
            value-field="drvSchoolCode"
            label-field="drvSchoolForShort"></select-dict-remote>
        </el-form-item>
        <el-form-item label="创建时间" style="width: 25%">
          <el-date-picker
            v-model="pickDateTime"
            @change="dataPickerHandler"
            :default-time="['00:00:00', '23:59:59']"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
        <el-form-item label="操作员" style="width: 18%">
          <el-input v-model="tableQuery.operator" clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
    <admin-space size="medium" class="admin-methods-btns" align="center" v-p="[60002]">
      <el-button icon="el-icon-download" size="medium" @click="getExcelHandler" :loading="loading">导出数据</el-button>
    </admin-space>
    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column prop="orderFlowNo" label="流水号">
          <template slot-scope="scope">
            <el-link
              v-if="$store.state.user.roleId == 1 || $store.state.user.permissionIdList.indexOf(60001) > -1"
              type="primary"
              @click="openView(scope.row)">
              {{ scope.row.orderFlowNo }}
            </el-link>
            <span v-else>{{ scope.row.orderFlowNo }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="创建时间" width="180" :formatter="$formatter.date(true)">
        </el-table-column>
        <el-table-column prop="drvSchoolForShort" label="驾校名称"></el-table-column>
        <el-table-column prop="orderFlowAmount" label="金额变更(元)">
          <template slot-scope="scope">
            <span
              :style="scope.row.orderFlowType == 1 || scope.row.orderFlowType == 20 ? 'color: #34b377' : 'color: #ff422d'">
              {{ scope.row.orderFlowType == 1 || scope.row.orderFlowType == 20 ? '+' : '-' }}{{ scope.row.orderFlowAmount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="orderFlowType" label="变更原因" :formatter="$formatter.dict($dict.orderFlowType)">
        </el-table-column>
        <el-table-column prop="operator" label="操作人员"></el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {rechargeFlowGetList, rechargeFlowExportxRechargeFlow, drvSchoolGetSchool} from '@/api/index';
import tableMinx from '@/mixin/table-page';
export default {
  mixins: [tableMinx(rechargeFlowGetList)],
  data() {
    return {
      drvSchoolGetSchool,
      loading: false,
      pickDateTime: [],
      tableQuery: {
        orderFlowNo: '',
        orderFlowType: '',
        drvSchoolCode: '',
        startTime: '',
        endTime: '',
        operator: '',
      },
    };
  },
  methods: {
    // 日期选中
    dataPickerHandler() {
      if (this.pickDateTime) {
        this.tableQuery.startTime = Date.parse(new Date(this.pickDateTime[0])) / 1000;
        this.tableQuery.endTime = Date.parse(new Date(this.pickDateTime[1])) / 1000;
      } else {
        this.tableQuery.startTime = '';
        this.tableQuery.endTime = '';
      }
    },

    // 查看明细
    async openView(row) {
      let vNode = this.$createElement((await import('./detail')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: '充值明细',
        width: '600px',
      });
    },

    // 导出明细
    getExcelHandler() {
      this.loading = true;
      rechargeFlowExportxRechargeFlow(this.tableQuery).then((res) => {
        this.loading = false;
        if (res.status == 200) {
          this.$utils.downloadFile(res);
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style>
.post-button {
  text-align: right;
  box-sizing: border-box;
  padding-right: 20px;
}
</style>
